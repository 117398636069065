<template>
  <v-expansion-panels accordion>
    <v-expansion-panel v-for="(instruction,i) in instructions"
                       :key="i">
      <v-expansion-panel-header color="primary darken-2">
        {{ instruction.name }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-card light>
          <v-card-text class="font-weight-medium text-center" v-html="instruction.content"/>
        </v-card>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  import appConfig from 'app-config/app.json'

  export default {
    name: 'InstallInstructions',

    data() {
      return {
        instructions: appConfig.installInstructions,
      }
    },
  }
</script>
